import React from "react";
import './style.css';
import { VscCircleLargeFilled } from 'react-icons/vsc';
import ScrollAnimation from 'react-animate-on-scroll';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AbiOfContract } from './contract'
import Web3 from 'web3';


function Header(props) {
    const MySwal = withReactContent(Swal)

   const [rtoken, setrtoken] = React.useState('0000000');
    const [rewardtoken, setrewardtoken] = React.useState("");
    const [wallets, setwallets] = React.useState("0000000");

    async function coinbal(){
        if(window.ethereum.chainId == '0x38') {
            await window.ethereum;
            var accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const account = accounts[0];

            var web3js = new Web3(window.ethereum);

            const CONTRACT_ADDR = "0x436d017a825e572ea264b71bf0671601f186d26b";

            let bestlifecontract = new web3js.eth.Contract(AbiOfContract, CONTRACT_ADDR);

            let wallet = await bestlifecontract.methods.totalwallet().call();
            
            setwallets( String(wallet).padStart(7, '0'))

            let rewards = await bestlifecontract.methods.rewards(account).call();
            
            
            setrtoken(rewards[3] )

                
            let rewardstoken = await bestlifecontract.methods.withdrawamount(account).call();
            console.log("rewardsss-",rewardstoken)
            console.log(rewards[3],"rewards3")
            if(rewards[3] > rewardstoken[0]) {
                
            const rewardsvalue =  parseInt(rewards[3] - rewardstoken[2]/2);         
             
            console.log("rewardsstoken---",rewardsvalue)
            setrewardtoken(rewardsvalue)
            
            } 
            else if(rewards[3] < rewardstoken[0]) {
                console.log(rewards[3])
               
            const rewardsvalue =   parseInt(rewards[3] -  rewardstoken[2]/2) ;         
            
            console.log("rewardstoken---",rewardsvalue) 
            setrewardtoken(rewardsvalue)
           
            } else {
                console.log("err")
            }
             

        }    
    }

    coinbal();

    async function withdraw() {
        if (window.ethereum.chainId == '0x38') {
            await window.ethereum;
            var accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const account = accounts[0];
     
            var web3js = new Web3(window.ethereum);
            const CONTRACT_ADDR = "0x436d017a825e572ea264b71bf0671601f186d26b";

            let bestlifecontract = new web3js.eth.Contract(AbiOfContract, CONTRACT_ADDR);

            console.log('rtoken---',rtoken)

            if(!rewardtoken) {

                bestlifecontract.methods.withdraw(account,rtoken).send({
                    from:account
                }).then((roundData)=>{
                    let rewardstoken =  bestlifecontract.methods.withdrawamount(account).call();
                    if(rtoken > rewardstoken) {
                
                        const rewardsvalue =   (rewardstoken[0]/2) - rtoken;         
                        setrewardtoken(rewardsvalue)
                        
                        } else {
                            
                        const rewardsvalue =   rtoken - (rewardstoken[0]/2) ;         
                         console.log(rewardsvalue)
                        setrewardtoken(rewardsvalue)
                       
                        }
                    
                })
            } else if(rewardtoken ) {

                let amountwith = await bestlifecontract.methods.withdrawamount(account).call();

            let accountaddr = web3js.utils.toChecksumAddress(account);
                    
                bestlifecontract.methods.withdraw(account,rewardtoken).send({
                        from:account
                    }).then((roundData)=>{
                        console.log("withdraw---",roundData);
                    })
              
            } else {
                console.log("err")
            }
            
            
           
        } else {
            MySwal.fire({
                title:<strong>Sorry</strong>,
                html:<i>Please connect Bsc Mainnet</i>,
                icon:'error' 
            })
        }
    }

    return (
        <div className="Header mt-5">

            <ul id="main_nav" class="nav nav-dots">
                <a class="nav-link scrollto" href="#section_1"> <VscCircleLargeFilled /> </a>
                <a class="nav-link scrollto" href="#section_2"> <VscCircleLargeFilled /> </a>
                <a class="nav-link scrollto" href="#section_3"> <VscCircleLargeFilled /> </a>
            </ul>

            <section>

            <div className="container min-vh-100" id="section_1">
                    <div className="row g-4 align-items-center text-center justify-content-around pb-4">
                        <div className="col-lg-5 col-6">
                            <h1 className="hero-heading">GOLDEN CLUB</h1>
                        </div>
                    </div>
                    <div className="row g-4 align-items-center text-center justify-content-around">
                        <div className="col-lg-5 col-8">
                            <div className="hero-section-2 text-center">
                                <h5 className="hero-section-text mb-3">Available Wallets</h5>
                                <span className="hero-section-number flipper">
                                    <div class="flipper">
                                    <div class="num">1,000,000</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-5 col-8">
                            <div className="hero-section-2 text-center">
                                <h5 className="hero-section-text mb-3">Registered Wallets</h5>
                                <span className="hero-section-number">
                                    <div class="flipper">
                                    <div class="num">{wallets}</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row g-4 align-items-center text-center justify-content-around py-4">
                        <div className="col-lg-5 col-8">
                            <div className="hero-section-1">
                                <h5 className="hero-section-text mb-3">Total Rewards Earned</h5>
                                <span className="hero-section-number">
                                    <div class="flipper2">
                                    <div class="num">{rewardtoken == ""? rtoken:rewardtoken}</div>
                                    </div>
                                </span>
                                <button class="colored-button-hero mt-3"  onClick={withdraw}>Withdraw</button>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center py-5 g-4">
                        <div className="col-lg-6">
                            <img src={"https://uploads-ssl.webflow.com/609ceefa674c5fcae90bca04/60a52786356bb76510f6caee_ByzGen%20Hero%20Homepage%402x%402x.png"} className="d-block mx-lg-auto img-fluid" alt="home-img-1" loading="lazy" />
                        </div>
                        <div className="col-lg-6">
                            <ScrollAnimation animateIn='flipInY'
                                animateOut='flipOutY'>
                                <h2 className="header-title">High Security. Simple Interface. Community Power.</h2>
                                <p className="section-text pt-4">The smart contract code has been successful audited by an independent company TRUST AUDIT which guarantees the protection of your assets from all external risks.A simple and intuitive interface that is optimized for any device will allow you to easily perform any action and will cheer you up from interacting with it.</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>

                {/* <div className="container min-vh-100 pt-5" id="section_1">
                    <div className="row g-4 align-items-center text-center justify-content-around">
                        <div className="col-lg-5 col-6">
                            <h1 className="hero-heading">GOLDEN CLUB</h1>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="hero-section-1">
                                <span className="hero-section-number">{found ? rewardtoken :rtoken}  XBLC token</span>
                                <h5 className="hero-section-text">Total Rewards Earned</h5>
                                <button class="colored-button-hero" onClick={withdraw}>Withdraw</button>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="hero-section-2">
                                
                                <span className="hero-section-number">{ props.brand ? props.brand:"000000" } Token</span>            
                                <h5 className="hero-section-text">Available</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center py-5 g-4">
                        <div className="col-lg-6">
                            <img src={"https://uploads-ssl.webflow.com/609ceefa674c5fcae90bca04/60a52786356bb76510f6caee_ByzGen%20Hero%20Homepage%402x%402x.png"} className="d-block mx-lg-auto img-fluid" alt="home-img-1" loading="lazy" />
                        </div>
                        <div className="col-lg-6">
                            <ScrollAnimation animateIn='flipInY'
                                animateOut='flipOutY'>
                                <h2 className="header-title">High Security. Simple Interface. Community Power.</h2>
                                <p className="section-text pt-4">The smart contract code has been successful audited by an independent company TRUST AUDIT which guarantees the protection of your assets from all external risks.A simple and intuitive interface that is optimized for any device will allow you to easily perform any action and will cheer you up from interacting with it.</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div> */}
                <div className="container py-5" id="section_2">
                    <div className="row row-cols-1 row-cols-lg-3 align-items-center justify-content-center g-4">
                        <ScrollAnimation animateIn='bounce'
                            initiallyVisible={true}
                            animateOnce={true}>

                            <div className="col">
                                <div class="card-basic">
                                    <div class="card-header header-basic">
                                        <h4>WEEKLY EARNING</h4>
                                    </div>
                                    <div class="card-body">
                                        <p><h2>604,800 XBLC</h2></p>
                                        <p><h2>Tokens</h2></p>
                                        
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='bounce'
                            initiallyVisible={true}
                            animateOnce={true}>
                            <div className="col">
                                <div class="card-standard">
                                    <div class="card-header header-standard">
                                        <h4>MONTHLY EARNING</h4>
                                    </div>
                                    <div class="card-body">
                                        <p><h2>2,620,800 XBLC</h2></p>
                                        <p><h2>Tokens</h2></p>
                                       
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='bounce'
                            initiallyVisible={true}
                            animateOnce={true}>
                            <div className="col">
                                <div class="card-premium">
                                    <div class="card-header header-premium">
                                        <h4>YEARLY EARNING</h4>
                                    </div>
                                    <div class="card-body">
                                        <p><h2>31,449,600 XBLC</h2></p>
                                        <p><h2>Tokens</h2></p>
                                        
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
                <div className="container">
                    <div className="row flex-lg-row-reverse align-items-center g-4">
                        <div className="col-lg-6">
                            <img src={"https://static.wixstatic.com/media/e7cd67_dd398c653074474285d2e9c0ecfb5b95~mv2.png/v1/fill/w_900,h_900,al_c,usm_0.66_1.00_0.01,enc_auto/mockup-01%20%20copy.png"} className="img-fluid" alt="home-img-1" loading="lazy" />
                        </div>
                        <div className="col-lg-4">
                            <ScrollAnimation animateIn='flipInY'
                                animateOut='flipOutY'>
                                <h2 className="header-title">Let XBLC Take Your life to Higher Grounds</h2>
                                <p className="section-text pt-4">The BestLife Coin (XBLC) token is a community-driven, DeFi Crypto Currency made on Binance smart chain with a deflationary supply and an automated redistribution mechanism that rewards holders.</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
                <div className="container py-5 mt-4" id="section_3" >

                    <div className="row align-items-center justify-content-center g-4">
                        <div className="col-lg-6 rules-section p-3" id="result">
                            <div class="container">
                                <h3>Rules</h3>
                                <p>1. Register and have a minimum wallet with 500,000,000 XBLC.</p>
                                <p>2. This wallet cannot lower a minimum of 500,000,000 XBLC.</p>
                                <p>3. If this wallet drops to 499,999,999 it will be removed and banned. (You will never be able to register this wallet again)</p>
                                <p>4. If this wallet is banned, it will not be eligible again to belong to the Golden Club. (You will be banned for life)</p>
                                <p>5. You will have to register with a new wallet with a minimum of 500,000,000 XBLC.</p>
                                <p>6. You can register as many wallets as you want by users. (Do not limit)</p>
                                <p>7. You will receive 1 token every second for the next 2 years guaranteed.<br/>
(604,800 XBLC TokensWeekly,
and 2,620,800 XBLC Monthly,<br/>
and 31,449,600 XBLC per year<br/>
and a total of 62,899,200 XBLC for 2 years)<br/>
In each registered and eligible wallet with a minimum of 500,000,000 XBLC.</p>
                                <p>8.This Golden Club can only register a maximum of 1,000,000 Wallets.</p>
                                <p>9.You can change your reward tokens every week or when you decide to redeem them.</p>
                                <p>10.Once the 1,000,000 wallets is registered and they have received their 2 consecutive years of rewards, this Golden Club will end.</p>
                            </div>

                        </div>
                    </div>
                </div>

            </section>


        </div>
    );
}


export default Header;
