import React from 'react';
import Nav from './Nav';
import './style.css';



function App() {

  return (

    <div className="App">
          <Nav />
    </div>
  );
}

export default App;
