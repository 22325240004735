
import './style.css';
import { Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaInstagram, FaTelegram, FaPhoneAlt, FaMailBulk,FaTiktok,FaYoutube } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';
import { AiOutlineMail } from 'react-icons/ai';
import Logo from "./Logo.png"
import Vimeo from './vimeo.png'
import Insta from './instagram.png'
import Youtube from './youtube.png'
import Tiktok from './tiktok.png'
import banner from './banner2.jpg'


import {
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    PinterestIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

function Footer() {

    return (
        <div className="Footer">
                        <div className="container-fluid py-4">
                <div className="row text-dark">
                    <div className="col-lg-12 mx-auto text-center">
        <a href="http://bestlifecoin.exchange/" target="_blank"><img src={banner} width="65%" heigth="20%"/></a>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-4">
                <h4 className='text-center'>Follow Us:</h4>
                <div className="row text-dark">
                    <div className="col-lg-12 mx-auto text-center">
                        <a href='https://twitter.com/BestLifeCoin/' target="_blank"><TwitterIcon size={40} round={true} className="me-4 footer-top-icons" /></a>
                        <a href='https://www.instagram.com/BestLifeCoin/' target="_blank"><img src={Insta} width="40" className="me-4 footer-top-icons"/></a>
                        <a href='https://api.whatsapp.com/send?phone=+12026430702&text&app_absent=0' target="_blank"><WhatsappIcon size={40} round={true}  className='footer-icons me-4 footer-top-icons' /></a>
                        <a href='https://www.youtube.com/channel/UCZ-6_gly7VLGrhgDOTmStUg' target="_blank"><img src={Youtube} width="35" className="me-4 footer-top-icons"/></a>
                        <a href='https://www.reddit.com/u/bestlifecoin' target="_blank"><RedditIcon size={40} round={true} className="me-4 footer-top-icons" /></a>
                        <a href='https://www.tiktok.com/@BestLifeCoin/' target="_blank"><img src={Tiktok} width="40" className="me-4 footer-top-icons"/></a>
                        <a href='https://www.pinterest.com/bestlifecoin/' target="_blank"><PinterestIcon size={40} round={true} className="me-4 footer-top-icons" /></a>
                        <a href='https://vimeo.com/bestlifecoin' target="_blank"><img src={Vimeo} width="40" className="me-4 footer-top-icons"/></a>
                        <a href='https://t.me/BestLifeCoin' target="_blank"><TelegramIcon size={40} round={true} className="me-4 footer-top-icons" /></a>
                    
                        {/* <a href='https://www.clarisco.com/' target="_blank"><FaFacebookF className='footer-icons me-4 footer-top-icons' /></a> */}
                        {/* <div class="middle">
                            <a class="btnf" href="#">
                                <i><FaFacebookF /></i>
                            </a>

                            <a class="btnf" href="#">
                                <i><FaFacebookF /></i>
                            </a>

                            <a class="btnf" href="#">
                                <i><FaFacebookF /></i>
                            </a>

                            <a class="btnf" href="#">
                                <i><FaFacebookF /></i>
                            </a>

                            <a class="btnf" href="#">
                                <i><FaFacebookF /></i>
                            </a>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="footer-section">
                {/* <div className="container py-2">
                    <div className="row align-items-center g-4">
                        <div className="col-lg-3 text-center">
                            <img src={Logo} width={60} alt="home-img-1" loading="lazy" />
                            <p className="mt-2">© GOLDEN CLUB 2022</p>
                        </div>
                        <div className="col-lg-3 text-center">
                            <h5 className='fs-14'>ABOUT</h5>
                            <h5 className='fs-14'>CARRER</h5>
                            <h5 className='fs-14'>TEAM</h5>
                        </div>
                        <div className="col-lg-4 text-center">
                            <h5 className='fs-14'><GoLocation className='fs-20 me-2' />21 XYY Street Rutland, VT</h5>
                            <h5 className='fs-14'><AiOutlineMail className='fs-20 me-2' />goldenclub@gmail.com</h5>
                            <h5 className='fs-14'><FaPhoneAlt className='fs-20 me-2' />9111</h5>
                        </div>

                    </div>
                </div> */}
                <div className="container-fluid bg-white py-2">
                    <div className="row text-dark align-items-center">
                        <div className="col-lg-5 mx-auto text-end">
                            <img src={Logo} width={150} alt="home-img-1" />
                        </div>
                        <div className="col-lg-7 mx-auto text-start">
                            <div className="fs-14">© 2022 Copyright: <a href="#" target="_blank" rel="noreferrer" className="fs-16 fw-500 text-dark">GOLDEN CLUB</a></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Footer;
